<template>
  <div class="menu">
    <ul class="menu__list" v-if="!isProcessing">
      <div class="menu__list__item--inline" v-if="user">
        <v-img class="menu__list__item__icon" :src="user.icon"
               @click="$router.push({ name: 'profile', params: { uid: uid } })" />
        <p class="menu__list__item__info"
           @click="$router.push({ name: 'profile', params: { uid: uid } })">{{ user.name }} </p>
        <v-btn class="menu__list__item__edit" @click="$router.push({ name: 'profile_edit' })" outlined>プロフィール編集</v-btn>
      </div>
      <li class="menu__list__item">
        <a class="menu__list__item__text" target="_blank"
           href="https://www.facebook.com/groups/656661221552950">Facebookグループ</a>
      </li>
      <li class="menu__list__item" v-if="!isExemption">
        <h2 class="menu__list__item__title">お支払い情報</h2>
        <router-link class="menu__list__item__text" :to="{ name: 'payment_card_change' }">カード情報の変更</router-link>
      </li>
      <li class="menu__list__item">
        <h2 class="menu__list__item__title">本アプリについて</h2>
        <a class="menu__list__item__text" target="_blank"
           href="https://docs.google.com/document/d/16H6ufWJqm-S6TbqlVaE_CcQ4lvrGZsiqZnsOaIz3TCo/edit?usp=sharing">利用規約</a>
        <a class="menu__list__item__text" target="_blank"
           href="https://docs.google.com/document/d/1DSjrvcDadl2W0W78Wl8U0BNVwStZSWhdgAhZVr91O14/edit?usp=sharing">プライバシーポリシー</a>
        <a class="menu__list__item__text" target="_blank"
           href="https://docs.google.com/document/d/1rDfL5JYZrnUWidEmDjqCsX4zix4C0w-j66ms8h7JeWc/edit?usp=sharing">特定商取引法に基づく表記</a>
      </li>
      <li class="menu__list__item">
        <a class="menu__list__item__text" target="_blank"
           href="https://forms.gle/7AYLvEQuZ4JrBqUX8">お問い合わせ</a>
      </li>
      <li class="menu__list__item">
        <a class="menu__list__item__text" target="_blank"
           href="https://linkdesign.jp/">運営会社</a>
      </li>
      <li class="menu__list__item">
        <p class="menu__list__item__text" @click="signout()">ログアウト</p>
      </li>
      <li class="menu__list__item">
        <router-link class="menu__list__item__text" :to="{ name: 'cancel' }">退会</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  async mounted () {
    // 自分自身が例外ユーザーかどうかの確認
    const isGotExemption = this.$store.getters['exemptions/isGot']
    if (!isGotExemption) await this.$store.dispatch('exemptions/getExemption', this.uid)

    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 同期処理が必要な情報を取得中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    },
    /**
     * @return {String} ユーザーID
     */
    uid () {
      return this.$store.getters['auth/uid']
    },
    /**
     * @return {Object} ユーザー
     */
    user () {
      return this.$store.getters['users/user'](this.uid)
    },
    /**
     * @return {Boolean} 自分自身が例外ユーザーかどうか
     */
    isExemption () {
      const exemption = this.$store.getters['exemptions/exemption']
      return !!exemption
    }
  },
  methods: {
    /**
     * サインアウト処理
     */
    signout () {
      this.$store.commit('setSubmitting', true)
      this.$store.dispatch('auth/signout').then(result => {
        if (result.status === 'error') {
          this.$store.commit('setTelop', { show: true, msg: 'ログアウトができませんでした。時間をおいて再度試してみてください。', type: 'error' })
          this.$store.commit('setSubmitting', false)
          return 0
        }

        this.$router.push({ name: 'login' })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.menu {
  width: 100vw;
  max-width: $max_width;
  margin: $header_height auto $padding_height;
  &__list {
    padding: 0;
    margin: 0;
    &__item {
      width: 100%;
      padding: $padding_height $padding_width;
      list-style: none;
      border-bottom: 1px solid $gray_color;
      &--inline {
        @extend .menu__list__item;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      &__icon {
        flex: initial;
        width: 38px;
        height: 38px;
        background-color: $gray_lighten_color;
        border-radius: 50%;
      }
      &__info {
        flex: 1;
        margin: 0 0 0 15px;
        font-size: 1.4rem;
      }
      &__edit {
        margin-left: 10px;
        font-size: 1.2rem;
        color: $orange_color !important;
        text-align: center;
        border: 1px solid $orange_color;
        border-radius: 15px;
        &.v-btn:not(.v-btn--round).v-size--default {
          height: auto;
          padding: 8px 10px;
        }
      }
      &__title {
        margin: 0 0 10px;
        font-size: 1.2rem;
        color: $orange_color;
      }
      &__text {
        display: block;
        margin: 0 0 10px 22px;
        font-size: 1.4rem;
        line-height: 2rem;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
